import { createReducer } from '@reduxjs/toolkit';
import {
  clearDefaultUserLanguages,
  clearUser,
  clearUserErrors,
  clearXtmTemplates,
  createUserError,
  getAllUsersSuccess,

  // getDefaultUserLanguages,
  // getDefaultUserLanguagesError,
  getDefaultUserLanguagesSuccess,
  getUserByIdSuccess,
  hideSessionExpired,
  loginUserError,
  loginUserSuccess,
  logoutUserSuccess,
  refreshTokenSuccess,
  showSessionExpired,
  usersPageableSuccess,
  verifyPassTokenError,
  verifyPassTokenSuccess,
  verifyTokenError,
  verifyTokenSuccess,
  xtmConnectError,
  xtmConnectSuccess,
  xtmConnectToCMSError,
  xtmConnectToCMSSuccess,
  xtmConnectUserError,
  xtmConnectUserSuccess,
  xtmGetConnectDataSuccess,
  xtmGetCustomersByIdSuccess,
  xtmGetCustomersSuccess,
  xtmGetTemplatesByIdSuccess,
  xtmGetTemplatesSuccess,
} from './user.actions';
import { IUserState } from './user.interface';

const initState: IUserState = {
  users: [],
  errors: {},
  auth: false,
  data: undefined,
  verifyFinished: false,
  verifyPassFinished: true,
  usersPageable: undefined,
  user: undefined,
  xtmAuthorizationId: undefined,
  customers: undefined,
  templates: undefined,
  connectData: undefined,
  xtmCMSconnected: undefined,
  defaultUserLanguages: undefined,
  sessionExpired: false,
};
export const userReducer = createReducer(initState, (builder) =>
  builder
    .addCase(loginUserSuccess, (state, action) => {
      state.auth = true;
      state.data = action.payload;
    })
    .addCase(loginUserError, (state, action) => {
      if (action.payload) {
        state.errors = action.payload;
      }
    })
    .addCase(verifyTokenSuccess, (state) => {
      state.auth = true;
      state.verifyFinished = true;
    })
    .addCase(verifyTokenError, (state) => {
      state.verifyFinished = true;
      state.auth = false;
    })
    .addCase(refreshTokenSuccess, (state, action) => {
      state.verifyFinished = true;
      state.auth = true;
      state.data = action.payload;
    })
    .addCase(logoutUserSuccess, (state) => {
      state.verifyFinished = true;
      state.auth = false;
      state.data = undefined;
    })
    .addCase(verifyPassTokenSuccess, (state) => {
      state.verifyPassFinished = true;
    })
    .addCase(verifyPassTokenError, (state) => {
      state.verifyPassFinished = true;
    })
    .addCase(getAllUsersSuccess, (state, action) => {
      state.users = action.payload;
    })
    .addCase(createUserError, (state, action) => {
      if (action.payload) {
        state.errors = action.payload;
      }
    })
    .addCase(usersPageableSuccess, (state, action) => {
      state.usersPageable = action.payload;
    })
    .addCase(getUserByIdSuccess, (state, action) => {
      state.user = action.payload;
    })
    .addCase(clearUserErrors, (state) => {
      state.errors = {};
    })
    .addCase(clearUser, (state) => {
      state.user = undefined;
      state.xtmAuthorizationId = undefined;
      state.customers = undefined;
      state.templates = undefined;
      state.connectData = undefined;
      state.xtmCMSconnected = undefined;
    })
    .addCase(xtmConnectError, (state) => {
      state.errors.xtmConnect = 'users.errors.connect';
      state.xtmAuthorizationId = undefined;
    })
    .addCase(xtmConnectUserError, (state) => {
      state.errors.xtmConnect = 'users.errors.connect';
      state.xtmAuthorizationId = undefined;
    })
    .addCase(
      xtmConnectSuccess,
      (state, { payload: { xtmAuthorizationId } }) => {
        state.xtmAuthorizationId = xtmAuthorizationId;
      },
    )
    .addCase(
      xtmConnectUserSuccess,
      (state, { payload: { xtmAuthorizationId } }) => {
        state.xtmAuthorizationId = xtmAuthorizationId;
      },
    )
    .addCase(xtmGetCustomersSuccess, (state, action) => {
      state.customers = action.payload;
    })
    .addCase(xtmGetCustomersByIdSuccess, (state, action) => {
      state.customers = action.payload;
    })
    .addCase(xtmGetTemplatesSuccess, (state, action) => {
      state.templates = action.payload;
    })
    .addCase(xtmGetTemplatesByIdSuccess, (state, action) => {
      state.templates = action.payload;
    })
    .addCase(xtmGetConnectDataSuccess, (state, action) => {
      state.connectData = action.payload;
    })
    .addCase(xtmConnectToCMSSuccess, (state, action) => {
      state.xtmCMSconnected = action.payload;
    })
    .addCase(clearXtmTemplates, (state) => {
      state.templates = undefined;
    })
    .addCase(xtmConnectToCMSError, (state) => {
      state.errors.cmsConnect = 'users.errors.connect';
      state.xtmCMSconnected = undefined;
    })
    .addCase(getDefaultUserLanguagesSuccess, (state, action) => {
      state.defaultUserLanguages = action.payload;
    })
    .addCase(showSessionExpired, (state) => {
      state.sessionExpired = true;
    })
    .addCase(hideSessionExpired, (state) => {
      state.sessionExpired = false;
    })
    .addCase(clearDefaultUserLanguages, (state) => {
      state.defaultUserLanguages = undefined;
    }),
);
