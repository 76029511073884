import CustomTableContainer from 'components/Table/CustomTable';
import TableToolbar from 'components/TableToolbar/TableToolbar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AppState } from 'store';
import { getClientsPageable } from 'store/client/client.actions';
import { getClientsPageableSelector } from 'store/client/client.selectors';
import { ClientDTO, Page } from 'utils/restApplicationClient';

interface IStateProps {
  clientsPageable?: Page<ClientDTO>;
  fetchSpinnerOverride?: boolean;
}

const TestTable = CustomTableContainer<ClientDTO>();

export class ClientsContainer extends Component<
  IStateProps & RouteComponentProps
> {
  headers = [
    { label: 'common.id', id: 'id' },
    { label: 'common.clientName', id: 'clientName' },
    { label: 'common.availability', id: 'status' },
    { label: 'common.creationDate', id: 'createdAt' },
    { label: 'common.modificationDate', id: 'modifiedAt' },
  ];
  onEdit = (row: ClientDTO): void => {
    this.props.history.push(`client/edit/${row.id}`);
  };

  onAdd = (): void => {
    this.props.history.push('client/add');
  };

  render(): JSX.Element {
    const { clientsPageable, fetchSpinnerOverride } = this.props;
    return (
      <div>
        <TableToolbar buttonLabelKey="client.add" onAdd={this.onAdd} />
        <TestTable
          headers={this.headers}
          onEdit={this.onEdit}
          paginationAction={getClientsPageable}
          pageable={clientsPageable}
          search={''}
          fetchSpinnerOverride={fetchSpinnerOverride}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  clientsPageable: getClientsPageableSelector(state),
});

export default connect(mapStateToProps)(withRouter(ClientsContainer));
