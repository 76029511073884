/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextField } from '@material-ui/core';
import { Component } from 'react';
import { FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { customInputStyles } from './CustomInput.style';

interface IProps {
  label: string;
  error?: string | boolean;
  touched?: boolean;
  inputProps: FieldInputProps<unknown, HTMLElement>;
  type?: string;
  testId?: string;
  disableAutoComplete?: boolean;
}

export class CustomField extends Component<IProps & WithTranslation> {
  render(): JSX.Element {
    const { t, error, touched, type, testId, inputProps, disableAutoComplete } =
      this.props;

    const shouldErrorDisplay = typeof error === 'boolean' || (error && touched);

    return (
      <div css={customInputStyles.root}>
        <span css={customInputStyles.label}>{t(this.props.label)}</span>
        <TextField
          css={shouldErrorDisplay ? customInputStyles.outlineError : {}}
          variant="outlined"
          margin="dense"
          inputProps={{ ...inputProps, 'data-testid': testId }}
          type={type}
          autoComplete={disableAutoComplete ? 'new-password' : type}
        />
        {shouldErrorDisplay && (
          <span
            css={customInputStyles.textErrorNoMargin}
            data-testid={`${testId}Error`}
          >
            {typeof error !== 'boolean' && t(error as string)}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomField);
