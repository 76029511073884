import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store';
import { ClientDTO, Page } from 'utils/restApplicationClient';

export const clientsPageableSelector = (
  state: AppState,
): Page<ClientDTO> | undefined => state.client.clientsPageable;

export const allClientsSelector = (state: AppState): ClientDTO[] =>
  state.client.clients;

export const errorsSelector = (
  state: AppState,
): { [key: string]: string | undefined } => state.client.errors;

export const clientSelector = (state: AppState): ClientDTO | undefined =>
  state.client.client;

export const getClientsPageableSelector = createSelector(
  clientsPageableSelector,
  (clients) => clients,
);

export const getAllClientsSelector = createSelector(
  allClientsSelector,
  (clients) => clients,
);

export const getClientErrorsSelector = createSelector(
  errorsSelector,
  (errors) => errors,
);

export const getClientSelector = createSelector(
  clientSelector,
  (updateClient) => updateClient,
);
