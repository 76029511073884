import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Select, TextField, Typography } from '@material-ui/core';

export const customInputStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    margin: 4px auto;
    width: 100%;
  `,
  label: css`
    font-weight: bold;
    color: #333;
    text-align: start;
    max-width: 300px;
    width: 295px;
  `,
  outlineError: css`
    &:hover {
      fieldset {
        border-color: red !important;
        border-width: 3px;
      }
    }

    fieldset {
      border-color: red !important;
      border-width: 2px;
    }
  `,
  textError: css`
    color: red;
    text-align: start;
    max-width: 400px;
    margin-left: 300px;
  `,
  textErrorNoMargin: css`
    color: red;
    text-align: start;
    max-width: 400px;
  `,
  checkbox: css`
    display: flex;
  `,
  radio: css`
    width: 100%;
  `,
};

export const CustomCheckboxGroupLabel = styled.span`
  font-weight: bold;
  color: #333;
  text-align: start;
  max-width: 300px;
  width: 295px;
  align-self: flex-start;
  margin-top: 12px;
`;

export const ErrorDiv = styled.div`
  width: 100%;
  text-align: left;
`;

export const FieldDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxFieldDiv = styled(FieldDiv)`
  margin-top: 12px;
  justify-content: flex-start;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 406px;
  align-items: center;
  & label {
    margin-left: 0;
  }
`;

export const CustomCheckboxGroupTypography = styled(Typography)`
  cursor: pointer;
  user-select: none;
`;

export const CustomCheckboxGroupDivRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomCheckboxGroupDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomInputError = styled.span`
  margin-left: 306px;
  display: flex;
  color: red;
  text-align: start;
  max-width: 400px;
`;

export const CustomCheckboxGroupTextField = styled(TextField)`
  max-width: 60px;
  margin: 0 6px !important;
`;

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
`;

export const StyledSelect = styled(Select)`
  width: 400px !important;
`;
export const RadioTypography = styled(Typography)`
  font-weight: bold !important;
  color: #333 !important;
  text-align: start !important;
  max-width: 300px !important;
  width: 295px !important;
`;

export const CheckboxTypography = styled(Typography)`
  font-weight: bold !important;
  color: #333 !important;
  text-align: start !important;
`;

export const CheckboxLabelTypography = styled(Typography)`
  font-weight: bold !important;
  color: #333 !important;
  text-align: start !important;
  padding-top: 10px;
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
