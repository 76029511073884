import { Dispatch } from '@reduxjs/toolkit';
import XtmConnectAccordion from 'components/Form/Accordion/XtmConnectAccordion/XtmConnectAccordion';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import ConnectedFields from 'components/Form/ConnectedFields/ConnectedFields';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomCheckboxGroup from 'components/Form/Input/CustomCheckboxGroup';
import CustomFieldRow from 'components/Form/Input/CustomFieldRow';
import { CustomInputError } from 'components/Form/Input/CustomInput.style';
import CustomSelect from 'components/Form/Input/CustomSelect';
import FormTitle from 'components/Form/Title/FormTitle';
import { Roles } from 'enums/roles';
import { UserRouteParameterEnum } from 'enums/userRouteParameter';
import { createForm, FormApi } from 'final-form';
import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import { getAllClients } from 'store/client/client.actions';
import { getAllClientsSelector } from 'store/client/client.selectors';
import {
  clearDefaultUserLanguages,
  clearUser,
  clearUserErrors,
  createUser,
  editUser,
  getUserById,
} from 'store/user/user.actions';
import { IEditUser } from 'store/user/user.interface';
import {
  getUserDataSelector,
  getUserErrorsSelector,
  getUserSelector,
  getXtmAuthorizationIdSelector,
} from 'store/user/user.selectors';
import { checkRoles } from 'utils/checkRoles';
import {
  composeValidators,
  email,
  fetchValidator,
  importMethodsValidator,
  optionalValidator,
  password,
  required,
} from 'utils/customValidators';
import {
  AuthenticationResult,
  ClientDTO,
  UserDTO,
} from 'utils/restApplicationClient';
import {
  CreateUserDTO,
  UpdateUserDTO,
} from 'utils/restApplicationClientTypeOverrides';

interface IStateProps {
  errors: { [key: string]: string };
  clients: ClientDTO[];
  user?: UserDTO;
  authorizationId?: string;
  currentUser: AuthenticationResult;
}

interface IDispatchProps {
  getAllClients: () => AppDispatch;
  createUser: (payload: CreateUserDTO) => AppDispatch;
  getUserById: (payload: string) => AppDispatch;
  editUser: (payload: IEditUser) => AppDispatch;
  clearUserErrors: () => AppDispatch;
  clearUser: () => AppDispatch;
  clearDefaultUserLanguages: () => AppDispatch;
}

interface IState {
  submitValues?: UpdateUserDTO | CreateUserDTO | IForm;
}

interface IProps {
  native?: boolean;
}

interface IMatchParams {
  id?: string;
  type?: UserRouteParameterEnum;
}

export interface IForm
  extends Omit<
    CreateUserDTO,
    'overrideTextLayersWithTranslations, importInterval'
  > {
  language: string;
  automaticImportInput: string;
}

export class AddUserContainer extends Component<
  WithTranslation &
    RouteComponentProps<IMatchParams> &
    IProps &
    IStateProps &
    IDispatchProps,
  IState
> {
  componentDidMount(): void {
    const {
      getAllClients,
      getUserById,
      match: {
        params: { id: userId },
      },
    } = this.props;
    clearUserErrors();
    clearUser();
    getAllClients();
    if (userId) {
      getUserById(userId);
    }
  }

  componentWillUnmount(): void {
    const { clearUser, clearUserErrors, clearDefaultUserLanguages } =
      this.props;
    clearUserErrors();
    clearUser();
    clearDefaultUserLanguages();
  }

  parseClientSelect(rows: ClientDTO[]): Array<{ value: string; name: string }> {
    return rows.map((client) => ({
      value: client.id,
      name: client.clientName,
    }));
  }

  getImportMethodValue = (
    key:
      | 'importAllJobs'
      | 'importInterval'
      | 'importPerJobs'
      | 'automaticImport',
    isClientAdmin?: boolean,
  ): boolean | number | undefined => {
    const {
      user,
      clients,
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return user ? (user.userSpecification as any)[key] : undefined;
    }
    if (isClientAdmin) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return clients[0] ? (clients[0] as any)[key] : undefined;
    }
    return undefined;
  };

  onSubmit = (values: IForm): void => {
    const {
      match: {
        params: { id: userId, type },
      },
    } = this.props;
    this.props.clearUserErrors();
    this.setState({ submitValues: values });
    const {
      automaticImportInput,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...rest
    } = values;
    const parameters: CreateUserDTO = {
      ...rest,
      importInterval: Number(automaticImportInput),
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { xtmAuthorizationId, ...editParameters } = parameters;
    if (userId && type === UserRouteParameterEnum.edit) {
      this.props.editUser({
        userId,
        updateUser: editParameters,
      });
    } else {
      this.props.createUser(parameters);
    }
  };

  handleClientChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ): void => {
    const client = this.props.clients.find(
      (client) => client.id === event.target.value,
    );
    this.form.change('importAllJobs', client?.importAllJobs);
    this.form.change('automaticImportInput', client?.importInterval.toString());
    this.form.change('importPerJobs', client?.importPerJobs);
    this.form.change('automaticImport', client?.automaticImport);
  };

  form: FormApi<IForm> = createForm({
    onSubmit: this.onSubmit,
    validate: importMethodsValidator,
  });

  render(): JSX.Element {
    const {
      match: {
        params: { id: userId, type },
      },
      native,
      errors,
      user,
      authorizationId,
      currentUser,
      t,
      clients,
    } = this.props;

    const shouldConnectedFieldsRender =
      user &&
      user.xtmProperties &&
      user.xtmProperties.xtmCustomerId &&
      user.xtmProperties.xtmTemplateId;

    const isClientAdmin = checkRoles(
      [Roles.ADMIN_CLIENT],
      currentUser.roles as Roles[],
    );

    const importAll = this.getImportMethodValue('importAllJobs', isClientAdmin);
    const importOne = this.getImportMethodValue('importPerJobs', isClientAdmin);
    const importInterval = this.getImportMethodValue(
      'automaticImport',
      isClientAdmin,
    );
    const importIntervalValue = this.getImportMethodValue(
      'importInterval',
      isClientAdmin,
    );
    return (
      <FormDiv>
        <FormTitle text={userId ? 'users.edit' : 'users.add'} />
        <Form
          onSubmit={this.onSubmit}
          form={this.form}
          subscription={{
            submitting: true,
            pristine: true,
            submitFailed: true,
            errors: true,
          }}
          render={({
            handleSubmit,
            submitting,
            pristine,
            errors: stateErrors,
            submitFailed,
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                validate={composeValidators([
                  required,
                  email,
                  fetchValidator(
                    errors['email'],
                    this.state?.submitValues?.email,
                  ),
                ])}
                key={errors['email'] ? 'emailError' : 'email'}
                initialValue={type === 'duplicate' ? '' : user && user.email}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.email"
                    error={meta.error}
                    touched={user ? true : meta.touched}
                    inputProps={input}
                    testId="emailInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="password"
                validate={
                  user && type === UserRouteParameterEnum.edit
                    ? (value: string): null | string =>
                        optionalValidator(value, password)
                    : password
                }
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.password"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="passwordInput"
                    type="password"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="firstName"
                validate={required}
                initialValue={user && user.userSpecification.firstName}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.firstName"
                    error={meta.error}
                    touched={user ? true : meta.touched}
                    inputProps={input}
                    testId="firstNameInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="lastName"
                validate={composeValidators([required])}
                initialValue={user && user.userSpecification.lastName}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomFieldRow
                    label="common.lastName"
                    error={meta.error}
                    touched={user ? true : meta.touched}
                    inputProps={input}
                    testId="lastNameInput"
                    disableAutoComplete={true}
                  />
                )}
              </Field>
              <Field
                name="clientUUID"
                validate={required}
                initialValue={
                  isClientAdmin
                    ? clients[0] && clients[0].id
                    : user && user.client.id
                }
              >
                {({ input, meta }): JSX.Element => (
                  <CustomSelect
                    label="common.client"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="clientSelect"
                    rows={this.parseClientSelect(clients)}
                    selectProps={{ native, disabled: isClientAdmin }}
                    onChange={this.handleClientChange}
                  />
                )}
              </Field>
              <Field
                name="interfaceLanguage"
                defaultValue="en-EN"
                validate={required}
              >
                {({ input, meta }): JSX.Element => (
                  <CustomSelect
                    label="common.language"
                    error={meta.error}
                    touched={meta.touched}
                    inputProps={input}
                    testId="languageSelect"
                    rows={[{ value: 'en-EN', name: 'English (UK)' }]}
                    width="50%"
                    selectProps={{ native }}
                  />
                )}
              </Field>
              <Field
                name="status"
                type="checkbox"
                initialValue={userId ? user && user.status : true}
              >
                {({ input }): JSX.Element => (
                  <CustomCheckbox
                    label="common.active"
                    inputProps={{ ...input }}
                    testId="checkboxInput"
                  />
                )}
              </Field>
              <Field
                name="generateAndUploadScreenshots"
                type="checkbox"
                initialValue={
                  userId
                    ? user &&
                      user.userSpecification.generateAndUploadScreenshots
                    : true
                }
              >
                {({ input }): JSX.Element => (
                  <CustomCheckbox
                    label="common.uploadScreenCheckbox"
                    inputProps={{ ...input }}
                    testId="checkboxInput"
                  />
                )}
              </Field>
              <CustomCheckboxGroup
                label="common.importTypes"
                testId="checkboxInput"
                rows={[
                  {
                    label: 'common.importOne',
                    initialValue: importOne as boolean,
                  },
                  {
                    label: 'common.importAll',
                    initialValue: importAll as boolean,
                  },
                  {
                    label: 'common.importPeriodical',
                    valueInput: true,
                    endText: 'common.minutes',
                    initialInputValue: (
                      importIntervalValue as number
                    )?.toString(),
                    initialValue: importInterval as boolean,
                  },
                ]}
                names={['importPerJobs', 'importAllJobs', 'automaticImport']}
              />
              {stateErrors && submitFailed && (
                <CustomInputError>{t(stateErrors.error)}</CustomInputError>
              )}
              <Field name="xtmAuthorizationId" validate={required}>
                {(inputProps): JSX.Element => (
                  <XtmConnectAccordion {...inputProps} />
                )}
              </Field>
              {(authorizationId || shouldConnectedFieldsRender) && (
                <ConnectedFields
                  form={this.form}
                  authorizationId={authorizationId}
                  user={user}
                />
              )}
              <ButtonContainer
                backTo="/users"
                submitting={submitting || pristine}
              />
            </form>
          )}
        />
      </FormDiv>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  getAllClients: (): AppDispatch => dispatch(getAllClients()),
  createUser: (payload: CreateUserDTO): AppDispatch =>
    dispatch(createUser(payload)),
  editUser: (payload: IEditUser): AppDispatch => dispatch(editUser(payload)),
  getUserById: (payload: string): AppDispatch => dispatch(getUserById(payload)),
  clearUserErrors: (): AppDispatch => dispatch(clearUserErrors()),
  clearUser: (): AppDispatch => dispatch(clearUser()),
  clearDefaultUserLanguages: (): AppDispatch =>
    dispatch(clearDefaultUserLanguages()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  clients: getAllClientsSelector(state),
  errors: getUserErrorsSelector(state),
  user: getUserSelector(state),
  authorizationId: getXtmAuthorizationIdSelector(state),
  currentUser: getUserDataSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AddUserContainer));
