import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiTablePagination: {
      toolbar: {
        background: 'white',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 400,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        background: 'white',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: 'white',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: '#ccedfa',
        },
      },
      //TODO remove table styles ?
      // root: {
      //   '&:nth-of-type(odd)': {
      //     backgroundColor: '#eff3f7',
      //   },
      // },
    },
    MuiTableContainer: {
      root: {
        width: 'auto',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '20px',
      },
    },
    MuiAppBar: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '24px',
        boxShadow: 'none',
        justifyContent: 'space-between',
      },
    },
    MuiTextField: {
      root: {
        background: 'white',
      },
    },
    MuiSelect: {
      root: {
        background: 'white',
      },
    },
  },
  palette: {
    primary: {
      main: '#2e3645',
      contrastText: '#FFFFFF',
      light: '5EB555',
    },
    secondary: {
      main: '#0096eb',
    },
  },
});
