/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch } from '@reduxjs/toolkit';
import ButtonContainer from 'components/Form/ButtonContainer/ButtonContainer';
import { EditData } from 'components/Form/CilentData/EditData';
import FormDiv from 'components/Form/Div/FormDiv';
import CustomCheckbox from 'components/Form/Input/CustomCheckbox';
import CustomCheckboxGroup from 'components/Form/Input/CustomCheckboxGroup';
import CustomFieldRow from 'components/Form/Input/CustomFieldRow';
import { CustomInputError } from 'components/Form/Input/CustomInput.style';
import FormTitle from 'components/Form/Title/FormTitle';
import { createForm } from 'final-form';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AppDispatch, AppState } from 'store';
import {
  clearClient,
  clearClientErrors,
  createClient,
  editClient,
  getClientById,
} from 'store/client/client.actions';
import {
  getClientErrorsSelector,
  getClientSelector,
} from 'store/client/client.selectors';
import { IEditClient } from 'types/api';
import {
  composeValidators,
  fetchValidator,
  importMethodsValidator,
  required,
} from 'utils/customValidators';
import { ClientDTO, CreateClientDTO } from 'utils/restApplicationClient';
import { addClientContainerStyles } from './addClientContainer.style';

interface IStateProps {
  errors: { [key: string]: string | undefined };
  client: ClientDTO | undefined;
}

interface IDispatchProps {
  createClient: (payload: CreateClientDTO) => AppDispatch;
  editClient: (payload: IEditClient) => AppDispatch;
  getClientById: (payload: string) => AppDispatch;
  clearClient: () => AppDispatch;
  clearClientErrors: () => AppDispatch;
}

interface IForm extends Omit<CreateClientDTO, 'importInterval'> {
  automaticImportInput: string;
}

// eslint-disable-next-line max-lines-per-function
export const AddClientForm = (
  props: IStateProps & IDispatchProps,
): JSX.Element => {
  const {
    params: { id: clientId },
  } = useRouteMatch<{ id: string }>();

  const { t } = useTranslation();
  const { errors, createClient, editClient } = props;
  const [submitValues, setSubmitValues] = useState<{
    clientName?: string;
    status?: boolean;
  }>({
    clientName: undefined,
    status: undefined,
  });

  const { client } = props;

  const onSubmit = (values: IForm): void => {
    props.clearClientErrors();
    setSubmitValues(values);
    const { automaticImportInput, ...rest } = values;
    const createClientParameters: CreateClientDTO = {
      ...rest,
      importInterval: Number(automaticImportInput),
    };
    if (clientId) {
      editClient({ clientId, updateClient: createClientParameters });
    } else {
      createClient(createClientParameters);
    }
  };

  useEffect(() => {
    if (clientId) {
      props.getClientById(clientId);
    }
    return (): void => {
      props.clearClient();
      props.clearClientErrors();
    };
  }, []);

  const form = createForm({
    onSubmit,
    validate: importMethodsValidator,
  });

  return (
    <FormDiv>
      <FormTitle text={clientId ? 'client.edit' : 'client.add'} />
      <Form
        form={form}
        onSubmit={onSubmit}
        subscription={{
          submitting: true,
          pristine: true,
          submitFailed: true,
          errors: true,
        }}
        render={({
          handleSubmit,
          submitting,
          pristine,
          errors: stateErrors,
          submitFailed,
        }): JSX.Element => (
          <form onSubmit={handleSubmit} css={addClientContainerStyles.form}>
            <Field
              name="clientName"
              initialValue={client && client.clientName}
              validate={composeValidators([
                required,
                fetchValidator(errors['clientName'], submitValues.clientName),
              ])}
              key={
                errors['clientName']
                  ? `clientNameError${submitValues.clientName}`
                  : 'clientName'
              }
            >
              {({ input, meta }): JSX.Element => (
                <CustomFieldRow
                  label={t('client.clientName')}
                  error={meta.error}
                  touched={errors['clientName'] ? true : meta.touched}
                  inputProps={input}
                  testId="clientNameInput"
                />
              )}
            </Field>
            <Field
              name="status"
              type="checkbox"
              initialValue={clientId ? client && client.status : true}
            >
              {({ input }): JSX.Element => (
                <CustomCheckbox
                  label="common.availability"
                  inputProps={input}
                  testId="checkboxInput"
                />
              )}
            </Field>
            <CustomCheckboxGroup
              label="common.importTypes"
              testId="checkboxInput"
              rows={[
                {
                  label: 'common.importOne',
                  initialValue: client && client.importPerJobs,
                },
                {
                  label: 'common.importAll',
                  initialValue: client && client.importAllJobs,
                },
                {
                  label: 'common.importPeriodical',
                  valueInput: true,
                  endText: 'common.minutes',
                  initialInputValue: client && client.importInterval.toString(),
                  initialValue: client && client.automaticImport,
                },
              ]}
              names={['importPerJobs', 'importAllJobs', 'automaticImport']}
            />
            {stateErrors && submitFailed && (
              <CustomInputError>{t(stateErrors.error)}</CustomInputError>
            )}
            {client && (
              <EditData
                createdAt={client.createdAt}
                modifiedAt={client.modifiedAt}
              />
            )}
            <ButtonContainer
              backTo="/clients"
              submitting={submitting || pristine}
            />
          </form>
        )}
      />
    </FormDiv>
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<AppDispatch>,
): IDispatchProps => ({
  createClient: (payload: CreateClientDTO): AppDispatch =>
    dispatch(createClient(payload)),
  editClient: (payload: IEditClient): AppDispatch =>
    dispatch(editClient(payload)),
  getClientById: (payload: string): AppDispatch =>
    dispatch(getClientById(payload)),
  clearClient: (): AppDispatch => dispatch(clearClient()),
  clearClientErrors: (): AppDispatch => dispatch(clearClientErrors()),
});

const mapStateToProps = (state: AppState): IStateProps => ({
  errors: getClientErrorsSelector(state),
  client: getClientSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClientForm);
