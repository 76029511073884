/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox } from '@material-ui/core';
import { Component, FormEvent, Fragment, InputHTMLAttributes } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  CheckboxFieldDiv,
  CustomCheckboxGroupDiv,
  CustomCheckboxGroupDivRow,
  CustomCheckboxGroupLabel,
  CustomCheckboxGroupTextField,
  CustomCheckboxGroupTypography,
} from './CustomInput.style';

interface IProps {
  label: string;
  rows: Array<{
    label: string;
    valueInput?: boolean;
    endText?: string;
    initialInputValue?: string;
    initialValue?: boolean;
  }>;
  names: Array<string>;
  testId?: string;
}

type PropType = IProps & WithTranslation;

export class CustomCheckboxGroup extends Component<PropType> {
  handleinputChange =
    (input: FieldInputProps<string, HTMLInputElement>) =>
    (event: FormEvent<HTMLInputElement>): void => {
      const value = Number((event.target as HTMLInputElement).value);
      if (value <= 9999 && value >= 1) {
        input.onChange(event);
      }
    };

  render(): JSX.Element {
    const { t, label, rows, testId, names } = this.props;
    return (
      <CheckboxFieldDiv>
        <CustomCheckboxGroupLabel>{t(label)}</CustomCheckboxGroupLabel>
        <CustomCheckboxGroupDiv>
          {rows.map((value, index) => {
            return (
              <CustomCheckboxGroupDivRow key={index}>
                <Field
                  name={names[index]}
                  type="checkbox"
                  initialValue={value.initialValue}
                >
                  {({ input }): JSX.Element => (
                    <Fragment>
                      <Checkbox
                        {...input}
                        inputProps={
                          {
                            'data-testid': testId,
                          } as InputHTMLAttributes<HTMLInputElement>
                        }
                        color={'secondary'}
                      />
                      <CustomCheckboxGroupTypography
                        variant="body2"
                        onClick={(): void => {
                          input.onChange(!input.checked);
                        }}
                      >
                        {t(value.label)}
                      </CustomCheckboxGroupTypography>
                      {value.valueInput && (
                        <Fragment>
                          <Field
                            name={`${names[index]}Input`}
                            key={value.initialInputValue}
                            initialValue={value.initialInputValue}
                          >
                            {({ input: _input }): JSX.Element => (
                              <CustomCheckboxGroupTextField
                                inputProps={{
                                  ..._input,
                                  onChange: this.handleinputChange(_input),
                                }}
                                margin="dense"
                                size="small"
                                type="number"
                                disabled={!input.checked}
                              />
                            )}
                          </Field>
                          {value.endText && (
                            <CustomCheckboxGroupTypography
                              variant="body2"
                              onClick={(): void => {
                                input.onChange(!input.checked);
                              }}
                            >
                              {t(value.endText)}
                            </CustomCheckboxGroupTypography>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Field>
              </CustomCheckboxGroupDivRow>
            );
          })}
        </CustomCheckboxGroupDiv>
      </CheckboxFieldDiv>
    );
  }
}

export default withTranslation()(CustomCheckboxGroup);
