import { ValidationErrors } from 'final-form';
import {
  EMAIL_REGEX,
  ISNUMBER,
  LENGTH_REGEX,
  PASSWORD_REGEX,
  PREFIX_REGEX,
  WHITESPACE_REGEX,
} from './customRegex';
import { isObjectEqual } from './objectEquality';

/**
 * Returns whether given field has a value
 *
 * @param value - Form string value to test against
 *
 * @returns Either null when validation is successful or translated error message
 */
const required = (value: string): string | null => {
  if (value && typeof value === 'string') {
    return null;
  }
  return 'validator.required';
};
/**
 * Returns whether given field is a valid email
 *
 * @param value - Form string value to test against
 *
 * @returns Either null when validation is successful or translated error message
 */
const email = (value: string): string | null => {
  // eslint-disable-next-line no-console
  if (EMAIL_REGEX.test(value)) {
    return null;
  }
  return 'validator.email';
};
/**
 * Returns whether given field is a valid password
 *
 * @param value - Form string value to test against
 *
 * @returns Either null when validation is successful or translated error message
 */
const password = (value: string): string | null => {
  if (PASSWORD_REGEX.test(value)) {
    return null;
  }
  return 'validator.password';
};
/**
 * Returns whether given field contains whitespaces
 *
 * @param value - Form string value to test against
 *
 * @returns Either null when validation is successful or translated error message
 */
const noWhitespace = (value: string): string | null => {
  if (!WHITESPACE_REGEX.test(value)) {
    return null;
  }
  return 'validator.whitespace';
};
/**
 * Returns whether field does not match
 *
 * @returns Either null when validation is successful or translated error message
 */
const comparePass = (values: {
  newPassword: string;
  confirmPassword: string;
}): ValidationErrors | Promise<ValidationErrors> | undefined => {
  if (values.newPassword !== values.confirmPassword) {
    return {
      confirmPassword: 'validator.confirmPassword',
    };
  }
  return undefined;
};

/**
 * Returns whether at least one checkbox is checked
 *
 * @returns Either undefined when validation is successful or translated error message
 */
const importMethodsValidator = (values: {
  automaticImport: boolean;
  importPerJobs: boolean;
  importAllJobs: boolean;
}): ValidationErrors | undefined => {
  if (
    !values.automaticImport &&
    !values.importPerJobs &&
    !values.importAllJobs
  ) {
    return { error: 'validator.importMethods' };
  }
  return undefined;
};

/**
 * Returns whether value is not a number
 *
 * @returns Either null when validation is successful or translated error message
 */
const isNumber = (value: string): string | null => {
  if (ISNUMBER.test(value)) {
    return null;
  }
  return 'validator.removeXTMFiles';
};

/**
 * Returns whether value length is composed of between 1 and 9
 *
 * @returns Either null when validation is successful or translated error message
 */
const isMaxLength = (value: string): string | null => {
  if (LENGTH_REGEX(1, 9).test(value)) {
    return null;
  }
  return 'validator.maxLength';
};

/**
 * Returns whether value does not contain any special characters
 *
 * @returns Either null when validation is successful or translated error message
 */
const isAllowedPrefix = (value: string): string | null => {
  if (!PREFIX_REGEX.test(value)) {
    return null;
  }
  return 'validator.XTMProjectPrefix';
};

/**
 * Returns reduced validator from given validator array
 *
 * @param validators - An array of validators
 *
 * @returns A reduced validator function
 */
const composeValidators =
  (
    validators: ((value: string) => boolean | string | null)[],
  ): ((value: string) => boolean | string | null) =>
  (value: string) => {
    return validators.reduce(
      (error: boolean | string | null, validator) => error || validator(value),
      null,
    );
  };
/**
 * Returns whether a field has an error field assigned to it in store and has different value than the one that threw an error
 *
 * @param error - An error returned from api
 *
 * @param previousValue - A value which caused error
 *
 * @returns Wheter given value is the same as the one that threw an error
 */
const fetchValidator =
  (error: string | undefined, previousValue: string | undefined) =>
  (value: string): string | null => {
    if (error && previousValue && previousValue === value) {
      return error;
    }
    return null;
  };
/**
 * Returns whether a submit object has an error assigned to it in store and has different value than the one that threw an error
 *
 * @param error - An error returned from api
 *
 * @param previousValue - Values which caused error
 *
 * @returns Wheter given value is the same as the one that threw an error
 */
const fetchValidatorObject =
  (error: string | undefined, previousValue: Record<string, unknown>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (values: Record<string, any>): {} => {
    const errors: Record<string, unknown> = {};
    if (error && previousValue && isObjectEqual(values, previousValue)) {
      const valuesParameters = Object.keys(values);
      valuesParameters.map((key) => {
        errors[key] = true;
      });
    }
    return errors;
  };

/**
 * Returns whether a field has an error field assigned to it in store
 *
 * @param error - Whether should display red outline
 *
 * @returns Whether store field is set to true
 */
function booleanValidator(error: boolean) {
  return function () {
    return error ? error : null;
  };
}

/**
 * Returns whether a field is not empty and validator
 *
 * @param value - field value
 * @param validator - optional validator
 */
function optionalValidator(value: string, validator: Function): null | string {
  if (value === undefined) {
    return null;
  }

  return validator(value);
}

export {
  required,
  email,
  composeValidators,
  password,
  noWhitespace,
  fetchValidator,
  comparePass,
  booleanValidator,
  fetchValidatorObject,
  isNumber,
  isAllowedPrefix,
  optionalValidator,
  isMaxLength,
  importMethodsValidator,
};
