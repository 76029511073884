import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of, timer } from 'rxjs';
import { catchError, delayWhen, pluck, switchMap, tap } from 'rxjs/operators';
import store from 'store';
import { showError, showSuccessful } from 'store/notify/notify.actions';
import { api, clearToken } from 'utils/axiosInstance';
import { AuthenticationResult } from 'utils/restApplicationClient';
import i18n from '../../utils/initTranslation';
import { setPaginationSearchSpinner } from '../table/table.actions';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  changeUserPassword,
  createUser,
  createUserError,
  createUserSuccess,
  editUser,
  editUserSuccess,
  getDefaultUserLanguages,
  getDefaultUserLanguagesError,
  getDefaultUserLanguagesSuccess,
  getUserById,
  getUserByIdError,
  getUserByIdSuccess,
  loginUser,
  loginUserError,
  loginUserSuccess,
  logoutUser,
  logoutUserSuccess,
  refreshTokenSuccess,
  resetPassword,
  resetPasswordError,
  resetPasswordSuccess,
  resetUserPassword,
  resetUserPasswordError,
  resetUserPasswordSuccess,
  sessionExpired,
  showSessionExpired,
  usersPageable,
  usersPageableError,
  usersPageableSuccess,
  verifyPassToken,
  verifyPassTokenError,
  verifyPassTokenSuccess,
  verifyToken,
  verifyTokenError,
  xtmConnect,
  xtmConnectError,
  xtmConnectSuccess,
  // xtmConnectToCMS,
  // xtmConnectToCMSError,
  // xtmConnectToCMSSuccess,
  xtmConnectUser,
  xtmConnectUserError,
  xtmConnectUserSuccess,
  xtmGetConnectData,
  xtmGetConnectDataError,
  xtmGetConnectDataSuccess,
  xtmGetCustomers,
  xtmGetCustomersById,
  xtmGetCustomersByIdError,
  xtmGetCustomersByIdSuccess,
  xtmGetCustomersError,
  xtmGetCustomersSuccess,
  xtmGetTemplates,
  xtmGetTemplatesById,
  xtmGetTemplatesByIdError,
  xtmGetTemplatesByIdSuccess,
  xtmGetTemplatesError,
  xtmGetTemplatesSuccess,
} from './user.actions';

export const loginEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginUser.type),
    switchMap(({ payload }) =>
      from(api.authenticateAdmin(payload)).pipe(
        switchMap(({ data }) => of(loginUserSuccess(data))),
        catchError(() => {
          return of(loginUserError({ login: 'auth.errors.login' }));
        }),
      ),
    ),
  );

export const logoutEpic: Epic = (action$, _, { browserHistory }) =>
  action$.pipe(
    ofType(logoutUser.type, verifyTokenError.type),
    tap(() => clearToken()),
    switchMap(() => {
      if (
        browserHistory.location.pathname !== '/changePassword' &&
        !/\/reset-password\/.*/.test(browserHistory.location.pathname)
      ) {
        browserHistory.push('/login');
      }
      return of(logoutUserSuccess());
    }),
  );

export const refreshTokenEpic: Epic = (action$) =>
  action$.pipe(
    ofType(loginUserSuccess.type, refreshTokenSuccess.type),
    pluck('payload'),
    delayWhen(({ ttl = 890 }: AuthenticationResult) =>
      timer((ttl - 10) * 1000),
    ),
    switchMap(() =>
      from(api.refreshToken()).pipe(
        switchMap(({ data }) => {
          return of(refreshTokenSuccess(data));
        }),
        catchError(() => {
          return of(logoutUser());
        }),
      ),
    ),
  );

export const resetPasswordEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(resetPassword.type),
    switchMap(({ payload: { email } }) =>
      from(
        api.sendResetPasswordEmailForAdmin({ email, language: i18n.language }),
      ).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: 'auth.success.resetPasswordSent' }),
          );
          return of(resetPasswordSuccess());
        }),
        catchError(() => {
          return of(resetPasswordError());
        }),
      ),
    ),
  );

export const resetUserPasswordEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(resetUserPassword.type),
    switchMap(({ payload: { email } }) =>
      from(api.sendResetPasswordEmail({ email, language: i18n.language })).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: 'auth.success.resetPasswordSent' }),
          );
          return of(resetUserPasswordSuccess());
        }),
        catchError(() => {
          return of(resetUserPasswordError());
        }),
      ),
    ),
  );

export const verifyTokenEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(verifyToken.type),
    switchMap(() =>
      from(api.refreshToken()).pipe(
        switchMap(({ data }) => {
          return of(refreshTokenSuccess(data));
        }),
        catchError(() => {
          return of(logoutUser());
        }),
      ),
    ),
  );

export const changePassEpic: Epic = (action$, state$, { browserHistory }) =>
  action$.pipe(
    ofType(changePassword.type),
    switchMap(({ payload }) =>
      from(api.changePassword(payload)).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: 'auth.success.changePassword' }),
          );
          browserHistory.push('/login');
          return of(changePasswordSuccess());
        }),
        catchError(() => {
          store.dispatch(showError({ message: 'auth.errors.login' }));
          return of(changePasswordError());
        }),
      ),
    ),
  );

export const changeUserPassEpic: Epic = (action$) =>
  action$.pipe(
    ofType(changeUserPassword.type),
    switchMap(({ payload }) =>
      from(api.changePassword(payload)).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: 'auth.success.changePassword' }),
          );
          window.location.href = 'https://app.contentful.com/';
          return of(changePasswordSuccess());
        }),
        catchError(() => {
          store.dispatch(showError({ message: 'auth.errors.login' }));
          return of(changePasswordError());
        }),
      ),
    ),
  );

export const verifyResetPasswordTokenEpic: Epic = (
  actions$,
  state$,
  { browserHistory },
) =>
  actions$.pipe(
    ofType(verifyPassToken.type),
    switchMap(({ payload: token }) =>
      from(api.verifyResetPasswordToken({ token })).pipe(
        switchMap(() => of(verifyPassTokenSuccess())),
        catchError(() => {
          browserHistory.push('/login');
          return of(verifyPassTokenError());
        }),
      ),
    ),
  );

export const addUserEpic: Epic = (action$, state$, { browserHistory }) =>
  action$.pipe(
    ofType(createUser.type),
    switchMap(({ payload }) =>
      from(api.createUser(payload)).pipe(
        switchMap(() => {
          store.dispatch(
            showSuccessful({ message: i18n.t('users.success.create') }),
          );
          browserHistory.push('/users');
          return of(createUserSuccess());
        }),
        catchError(({ response: { status } }) => {
          store.dispatch(showError({ message: 'users.errors.create' }));
          if (status === 409) {
            return of(createUserError({ email: 'users.errors.duplicate' }));
          } else if (status === 403) {
            return of(sessionExpired(true));
          }
          return of(createUserError());
        }),
      ),
    ),
  );

export const getUsersPageableEpic: Epic = (action$) =>
  action$.pipe(
    ofType(usersPageable.type),
    switchMap(({ payload }) =>
      from(api.getAllUsers(payload)).pipe(
        switchMap(({ data }) => {
          store.dispatch(setPaginationSearchSpinner(false));
          return of(usersPageableSuccess(data));
        }),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }
          store.dispatch(setPaginationSearchSpinner(false));
          store.dispatch(showError({ message: 'users.errors.getAll' }));
          return of(usersPageableError());
        }),
      ),
    ),
  );

export const editUserEpic: Epic = (actions$, state$, { browserHistory }) =>
  actions$.pipe(
    ofType(editUser.type),
    switchMap((res) => {
      return from(
        api.editUser(res.payload.userId, res.payload.updateUser),
      ).pipe(
        switchMap(() => {
          store.dispatch(showSuccessful({ message: 'users.success.edit' }));
          browserHistory.push('/users');
          return of(editUserSuccess());
        }),
        catchError(({ response: { status } }) => {
          store.dispatch(showError({ message: 'users.errors.edit' }));
          if (status === 409) {
            return of(createUserError({ email: 'users.errors.duplicate' }));
          } else if (status === 403) {
            return of(sessionExpired(true));
          }
          return of(createUserError());
        }),
      );
    }),
  );

export const getUserEpic: Epic = (actions$, state$, { browserHistory }) =>
  actions$.pipe(
    ofType(getUserById.type),
    switchMap(({ payload }) =>
      from(api.getUser(payload)).pipe(
        switchMap(({ data }) => of(getUserByIdSuccess(data))),
      ),
    ),
    catchError(({ response: { status } }) => {
      if (status === 403) {
        return of(sessionExpired(true));
      }
      browserHistory.push('/users');
      store.dispatch(showError({ message: 'users.errors.noId' }));
      return of(getUserByIdError());
    }),
  );

export const connectXtmEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmConnect.type),
    switchMap(({ payload }) =>
      from(api.connectToXtm(payload)).pipe(
        switchMap(({ data }) => of(xtmConnectSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }
          return of(xtmConnectError());
        }),
      ),
    ),
  );

export const connectXtmUserEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmConnectUser.type),
    switchMap(({ payload }) =>
      from(api.reconnectToXtm(payload.userId, payload.xtmParameters)).pipe(
        switchMap(({ data }) => {
          return of(xtmConnectUserSuccess(data));
        }),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }
          return of(xtmConnectUserError());
        }),
      ),
    ),
  );

export const getCustomersEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(
      xtmGetCustomers.type,
      xtmConnectSuccess.type,
      xtmConnectUserSuccess.type,
    ),
    switchMap(({ payload }) =>
      from(
        api.getXTMCustomers$GET$api_xtm_customers({
          xtmAuthId: payload.xtmAuthorizationId,
        }),
      ).pipe(
        switchMap(({ data }) => of(xtmGetCustomersSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(xtmGetCustomersError());
        }),
      ),
    ),
  );

export const getTemplatesEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetTemplates.type),
    switchMap(({ payload }) =>
      from(api.getXTMTemplates(payload)).pipe(
        switchMap(({ data }) => of(xtmGetTemplatesSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(xtmGetTemplatesError());
        }),
      ),
    ),
  );

export const getCustomersByIdEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetCustomersById.type),
    switchMap(({ payload }) =>
      from(api.getXTMCustomersForExistingUser(payload)).pipe(
        switchMap(({ data }) => of(xtmGetCustomersByIdSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(xtmGetCustomersByIdError());
        }),
      ),
    ),
  );

export const getTemplatesByIdEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetTemplatesById.type),
    switchMap(({ payload }) =>
      from(
        api.getXTMTemplatesForExistingUser$GET$api_xtm_templates_userId(
          payload.userId,
          payload.queryParams,
        ),
      ).pipe(
        switchMap(({ data }) => of(xtmGetTemplatesByIdSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(xtmGetTemplatesByIdError());
        }),
      ),
    ),
  );

export const getConnectDataEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(xtmGetConnectData.type),
    switchMap(({ payload }) =>
      from(api.getXTMConnectionParameters(payload)).pipe(
        switchMap(({ data }) => of(xtmGetConnectDataSuccess(data))),
        catchError(({ response: { status } }) => {
          if (status === 403) {
            return of(sessionExpired(true));
          }

          return of(xtmGetConnectDataError());
        }),
      ),
    ),
  );

export const showSessionExpiredEpic: Epic = (actions$) =>
  actions$.pipe(
    ofType(sessionExpired.type),
    switchMap(({ payload }) => {
      if (payload) {
        return of(showSessionExpired());
      }
      return of(showSessionExpired());
    }),
  );

const getDefaultUserLanguagesList: Epic = (actions$) =>
  actions$.pipe(
    ofType(getDefaultUserLanguages.type),
    switchMap(({ payload }) =>
      from(
        api.getAvailableSourceLanguages(payload.xtmCustomerId, {
          ...payload.queryParams,
        }),
      ).pipe(
        switchMap(({ data }) => of(getDefaultUserLanguagesSuccess(data))),
        catchError(() => {
          return of(getDefaultUserLanguagesError());
        }),
      ),
    ),
  );

export const userEpics = combineEpics(
  loginEpic,
  logoutEpic,
  resetPasswordEpic,
  refreshTokenEpic,
  verifyTokenEpic,
  verifyResetPasswordTokenEpic,
  changePassEpic,
  getUsersPageableEpic,
  addUserEpic,
  getUserEpic,
  editUserEpic,
  connectXtmEpic,
  getCustomersEpic,
  getTemplatesEpic,
  getCustomersByIdEpic,
  getTemplatesByIdEpic,
  getConnectDataEpic,
  showSessionExpiredEpic,
  connectXtmUserEpic,
  resetUserPasswordEpic,
  changeUserPassEpic,
  getDefaultUserLanguagesList,
);
